.sidebar {
  height: 100vh;
  width: 5pc;
  background-color: rgb(239, 239, 239);

  .cmp-sidebar__burger {
    border: none;
    background: none;
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
    height: 5pc;
    width: 100%;

    .ham {
      -webkit-transition-duration: .3s;
      transition-duration: .3s;
      transition-property: -webkit-transform;
      transition-property: transform,-webkit-transform;
      transition-timing-function: cubic-bezier(0,0,.2,1);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .line {
      transition: stroke-dasharray .4s,stroke-dashoffset .4s;
    }
    .bottom, .top {
      stroke-dasharray: 40 121;
    }
  }

  .cmp-sidebar {
    display: flex;
    justify-content: space-between;
    -webkit-box-pack: justify;
    position: fixed;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    --tw-bg-opacity: 1;
    background-color: rgb(239 239 239/var(--tw-bg-opacity));
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    width: 5pc;
  }
  .cmp-sidebar__buttons {
    display: flex;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    background-color: rgb(239 239 239/1);
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 0;
    position: static;


    .cmp-sidebar__button {
      text-decoration: none;
      background-color: #363636;
      width: 5pc;
      height: 5pc;
      margin-top: .125rem;
      padding-bottom: 18px;
      padding-top: 18px;
      @media (min-width: 640px) {
        opacity: 1;
        color: rgb(255 255 255);
      }
    }

  }


  .ui-icon {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 19px;
    margin: 0 auto;
    margin-right: auto;
    margin-left: auto;
    width: 19px;
  }

  .ui-icon__text {
    font-family: NeueFrutigerWorldBold,sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    line-height: 150%;
    line-height: 1.25;
  }

  .ui-icon__globe {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-image: url(../imgs/locationWhite.svg);
  }

  .ui-icon__envelope {
    height: 18px;
    width: 21px;
    background-image: url(../imgs/contactWhite.svg);
  }

}
