.modal {

  .modal__container {
    @media (max-width: 767px) {
      max-height: 85vh;
    }
  }

  .italic {
    font-style: italic;
  }

  h1 {
    font-weight: bold;
    font-size: 1.15rem;
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 1rem;
  }

  .modal__btn {
    width: 100%;
  }

  .cookie-icon {
    text-align: center;
    font-size: 40px;
  }
  .mail-template {
    font-style: italic;
    padding-bottom: 1rem;
    padding-left: 20px;

    @media screen and (min-width: 768px){
      padding-left: 45px;
    }

    p {
      margin-bottom: 0.5rem;
    }

    .placeholder {
      color: rgb(255 75 79);
    }

    input[type="radio"] {
      display: none;
      & + label {
        cursor: pointer;
        display: none;
      }
      &:checked + label {
        display: inline;
      }
    }

    /*input:focus-visible {
      color: inherit;
      border: 0 solid #000;
      border-bottom-width: 1px;
      outline: none;
    }*/

    #invisible-date {
      font-size: 0;
    }

    .field-filled {
      color: #000000;
    }
  }

  #datepicker {
    appearance: none;
    -webkit-appearance: none;
    line-height: 1.5;
    color: red;
    font-family: -apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif;
    background-color: transparent;
    font-style: italic;

    &.field-filled {
      color: #000c;
    }
  }

  .cook-it {
    position: absolute;
    bottom: 0;
    left: 0;
    visibility: hidden;
    transition: opacity .3s ease;
    padding-top: 3px;
    span {
      position: relative;
      display: inline-block;
      transform: translateX(0) translateY(0);
      transition: transform .5s ease-out;
    }

    @media screen and (max-width: 1200px){
      display: none !important;
      visibility: hidden;
    }
  }

  a.send-mail-to-plan-net {
    color: rgb(255 75 79);
    font-weight: bold;
    text-decoration: underline;

    &.sended + {
      .cook-it {
        visibility: visible;
        span {
          transform: translateX(10px) translateY(-3px);
        }
      }
    }

  }

}

#modal-2 .modal__overlay {
  background: none;
}
