body {
  display: flex;

  margin: 0;
  padding: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.root-container {
  width: calc(100% - 5pc);
  background-color: #fff;
  height: auto;
  overflow: clip;
}


.large-image {
  width: 100%;
  img {
    width: 100%;
    object-fit: cover;
    max-height: 700px;
  }
}


.cmp-hero__content {
  padding: 4rem 88px;

  .cmp-hero__title .cmp-title h2, .cmp-hero__title .cmp-title h3 {
    font-family: service-20, NeueFrutigerWorldLight, sans-serif;
    font-size: 4.75rem;
    line-height: 100%;

    @media screen and (min-width: 1200px){
      font-size: 7.375rem;
      line-height: 100%;
    }
  }

  .cmp-hero__pretitle {

    h3 {
      text-transform: uppercase;
      margin-bottom: 0;
      display: inline-flex;
      font-family: NeueFrutigerWorldExtraBlack,sans-serif;
      transform: translate(0,0) rotate(180deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
      --tw-rotate: 180deg;
      --tw-text-opacity: 1;
      bottom: .5rem;
      color: rgb(255 75 79/var(--tw-text-opacity));
      position: absolute;
      white-space: nowrap;
      -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
      writing-mode: vertical-lr;

      &::after {
        --tw-border-opacity: 1;
        border-bottom-width: 2px;
        border-color: rgb(0 0 0/var(--tw-border-opacity));
        border-style: solid;
        bottom: 0;
        content: var(--tw-content);
        display: block;
        left: 0;
        position: absolute;
        -webkit-transition-duration: .15s;
        transition-duration: .15s;
        -webkit-transition-property: all;
        transition-property: all;
        -webkit-transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        width: 100%;


        @media (min-width: 1024px) {
          --tw-rotate: 90deg;
          --tw-border-opacity: 1;
          border-color: rgb(255 75 79);
          bottom: -170px;
          content: var(--tw-content);
          left: -450%;
          width: 300px;
          transform: translate(0,0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
        }
      }
    }
  }

}


