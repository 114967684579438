.header {
  display: flex;
  justify-content: end;
  padding: 1.75rem 88px 3rem;

  .cmp-image__image {
    width: 270px;
  }

}
