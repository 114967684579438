@font-face {
  font-display: swap;
  font-family: NeueFrutigerWorldExtraBlack;
  src: url(../fonts/NeueFrutigerWorldExtraBlack.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: NeueFrutigerWorldBold;
  src: url(../fonts/NeueFrutigerWorldBold.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: NeueFrutigerWorldRegular;
  src: url(../fonts/NeueFrutigerWorldRegular.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: NeueFrutigerWorldLight;
  src: url(../fonts/NeueFrutigerWorldLight.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: service-20;
  src: url(../fonts/service-20.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: service-30;
  src: url(../fonts/service-30.woff2) format("woff2")
}
